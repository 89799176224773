import "./Tools.scss";
import ScrollAnimationSection from "../../../components/scrollAnimSec/ScrollAnimSec";


const Tools = ({title, toolsList}) => {


  return (
    <section className="tools p_t p_b">
    
      <div className="content_wrap">
        <ScrollAnimationSection>
          <h2>{title}</h2>
        </ScrollAnimationSection>
        <div className="tools_grid">
          {toolsList?.map((data) => {
            return (
              <div className="tool_box" key={data.id}>
                <div className="tool_circle">
                  <div className="icon_wrap">{data.icon}</div>
                  <h5>{data.title}</h5>
                </div>
              </div>
            );
          })}

          <div className="tool_box">
            <div className="tool_circle">
              <h5>& More</h5>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Tools;
