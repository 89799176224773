import { useLocation } from "react-router-dom";
import PrimaryBtn from "../../../components/primaryBtn/PrimaryBtn";
import { paths } from "../../../utils/path";
import "./ServiceLanding.scss";
import { images } from "../../../utils/images";
import { Fragment } from "react";

const ServiceLanding = ({
  bgImage,
  overlay,
  mainTitle,
  mainPara1,
  mainPara2,
}) => {
  const goToServic = () => {
    const section = document.getElementById("services");
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth",
      });
    }
  };
  return (
    <section className="web_dev_landing p_b">
      {bgImage && (
        <Fragment>
          {overlay && <div className="overlay_bg"></div>}

          <img src={bgImage} alt="Bg" className="img_bg" loading="lazy" />
        </Fragment>
      )}

      <div className="content_wrap">
        <div className="title pb-5">
          <h1>{mainTitle}</h1>
          <p className="para_text">{mainPara1}</p>
          {mainPara2 && <p className="para_text">{mainPara2}</p>}
          <PrimaryBtn
            varient="dark"
            title="Explore more"
            onClick={goToServic}
          />
        </div>
      </div>
    </section>
  );
};

export default ServiceLanding;
