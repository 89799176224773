import { images } from "./images";

export const testimonial = [
  {
    id: 0,
    profile: images.alistair,
    isLogo:false,
    name: "Alistair",
    post: "Founder, Gate8",
    text: "",
    video: true,
    vId:"nq9cdqm2px"
  },
  {
    id: 1,
    profile: images.alon,
    isLogo:false,
    name: "Alon",
    post: "CEO, Sec4cyber",
    text: "",
    video: true,
    vId:"v01c5qxhz0"
  },
  {
    id: 2,
    profile: images.praveena,
    
    isLogo:false,
    name: "Praveena",
    post: "Founder, Tacticons",
    text: "Websites are the modern business window. I advocate for freshness, uniqueness, and simplicity. Supagrow understood my vision, creating a website that reflects both the business nature and creativity. Their passionate team made necessary adjustments until our needs were met, keeping us informed at every step and ensuring timely project completion. I'm delighted with their service.",
  },
  {
    id: 3,
    profile: images.sanjana,
    
    isLogo:false,
    name: "Sanjana",
    post: "Founder, Aglow",
    text: "Supagrow crafted my brand's Aglow website, bringing my vision to life. Their fantastic team, including Ankita and Viraj was helpful, addressing my doubts promptly. Post-launch, they consistently checked in to ensure the website's smooth operation, making me feel valued as more than just a client. I highly recommend Supagrow for their professionalism, adherence to timelines, and exceptional skills.",
  },
  {
    id: 4,
    profile: images.utsav,
    isLogo:false,
    name: "Utsav",
    post: "Founder, TFG",
    text: "Supagrow helped us move to a custom coded platform for our service based startup. We liked their inputs while building our website. They understood our requirements in the project and extended it to the website. It was a smooth process for onboarding clients, since our payment gateways were integrated into the website.",
  },
  {
    id: 5,
    profile: images.rashmika,
    isLogo:false,
    name: "Rashmi Sundaram",
    post: "Founder, The VoiceBank India",
    text: "The understanding in terms of what our expectations are was what I really liked. All discussions were open minded, exchange of ideas was good and it was a smooth experience.",
  },
  {
    id: 6,
    profile: images.upasna,
    isLogo:false,
    name: "Upasana",
    post: "CEO, BlueBricks",
    text: "Great team to work with, they clearly understood requirements, were open for feedback and changes and delivered well.",
  },
  {
    id: 7,
    profile: images.keystonLogo,
    isLogo:true,
    name: "Janet Noronha",
    post: "Senior Marketing Manager",
    text: "Working with Supagrow has been an absolute pleasure.  Their creativity, and technical expertise resulted in a website that is user-friendly and optimized for performance.  They were open to changes and responsive throughout the process. We highly recommend Supagrow for their professional approach and adherence to timelines",
  },
];
