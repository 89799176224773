import { Fragment, createRef } from "react";
import "./ServiceMidTab.scss";
import ServiceBenefits from "../serviceBenefits/ServiceBenefits";
import ServiceOfferings from "../serviceOfferings/ServiceOfferings";
import ServiceTestimonial from "../serviceTestimonial/ServiceTestimonial";

import Faq from "../../../pages/home/Faq/Faq";
import ScrollSpy from "react-scrollspy-navigation";
import ServicesCaseStudy from "../servicesCaseStudy/ServicesCaseStudy";
import ServiceClients from "../servicesClients/ServiceClients";
import ServiceProcess from "../serviceProcess/ServiceProcess";
import { useLocation } from "react-router-dom";
import Tools from "../tools/Tools";

import { servicesData } from "../../../utils/servicesData";

const ServiceMidTab = () => {
  const location = useLocation();

  const goTo = (secId) => {
    const section = document.getElementById(secId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth",
      });
    }
  };


  const target = () => {
    return servicesData?.find((obj) => obj?.slug === location?.pathname);
  };

  const offeringDataList = [
    {
      id: "01",
      title: "Experienced team",
      text: " Highly skilled team proficient in diverse technologies, delivering expertise tailored to your needs.",
    },
    {
      id: "02",
      title: "Structured development",
      text: "A structured development plan with clear milestones to ensure timely progress and alignment with project goals.",
    },
    {
      id: "03",
      title: "Responsive to project needs",
      text: "Adaptable and responsive to evolving project requirements, ensuring seamless adjustments and optimal outcomes.",
    },
    {
      id: "04",
      title: "Innovate your brand.",
      text: " Elevate your brand with innovative experiences that captivate and engage your audience, setting you apart in your industry.",
    },
    {
      id: "05",
      title: "Strategy driven execution",
      text: " Execute with strategy at the core, ensuring every action aligns with your long-term goals and drives impactful results.",
    },
  ];

  return (
    <Fragment>
      <div className="services" id="services">
        <section className="service_mid_tab">
          <div className="content_wrap">
            <div className="md_tab_line">
              <ScrollSpy activeClass="active">
                <div className="service_tab_wraper">
                  <div className="btn_wrap">
                    <button type="button" onClick={() => goTo("sec1")}>
                      Services
                    </button>

                    <button type="button" onClick={() => goTo("sec2")}>
                      Process
                    </button>

                    <button type="button" onClick={() => goTo("sec3")}>
                      Why&nbsp;Us
                    </button>

                    <button type="button" onClick={() => goTo("sec4")}>
                      Clients
                    </button>

                    <button type="button" onClick={() => goTo("sec5")}>
                      Review
                    </button>

                    <button type="button" onClick={() => goTo("sec6")}>
                      CaseStudy
                    </button>

                    <button type="button" onClick={() => goTo("sec7")}>
                      Stack
                    </button>

                    <button type="button" onClick={() => goTo("sec8")}>
                      FAQ
                    </button>
                  </div>
                  
                  <a href="#sec1" ref={createRef()}>
                    Services
                  </a>
                  <a href="#sec2" ref={createRef()}>
                    Process
                  </a>
                  <a href="#sec3" ref={createRef()}>
                    Why&nbsp;Us
                  </a>
                  <a href="#sec4" ref={createRef()}>
                    Clients
                  </a>
                  <a href="#sec5" ref={createRef()}>
                    Review
                  </a>
                  <a href="#sec6" ref={createRef()}>
                    Case&nbsp;Study
                  </a>
                  <a href="#sec7" ref={createRef()}>
                    Stack
                  </a>
                  <a href="#sec8" ref={createRef()}>
                    FAQ
                  </a>
                </div>
              </ScrollSpy>
            </div>
          </div>
        </section>

        <div id="sec1">
          <ServiceBenefits />
        </div>

        <div id="sec2">
          <ServiceProcess
            data={target()?.process?.list}
            title={target()?.process?.title}
          />
        </div>

        <div id="sec3">
          <ServiceOfferings
          title="Why Supagrow?"
          why={offeringDataList} />
        </div>

        <div id="sec4">
          <ServiceClients />
        </div>

        <div id="sec5">
          <ServiceTestimonial />
        </div>

        <div id="sec6">
          <ServicesCaseStudy />
        </div>
        <div id="sec7">
          <Tools
          title="Our Stack"
          toolsList={target()?.tools} />
        </div>

        <div id="sec8">
          <Faq faqData={target()?.faqList} />
        </div>
      </div>
    </Fragment>
  );
};

export default ServiceMidTab;
