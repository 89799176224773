import { NavLink } from "react-router-dom";
import "./ServicesLanding.scss";
import { GoArrowRight } from "react-icons/go";

import { paths } from "../../../utils/path";
import { Fragment } from "react";

const ServicesLanding = () => {
  const linkList = [
    {
      id: "01",
      text: "Web Development",
      link: paths.webDev,
    },
    {
      id: "02",
      text: "UI/UX",
      link: paths.uiux,
    },
    {
      id: "03",
      text: "SEO",
      link: paths.seo,
    },
    {
      id: "04",
      text: "Mobile App Development",
      link: paths.mobileApp,
    },
    {
      id: "05",
      text: "Data Analytics",
      link: paths.dataAnalytics,
    },
    {
      id: "06",
      text: "Ecommerce",
      link: paths.ecom,
    },
    {
      id: "07",
      text: "LMS",
      link: paths.lms,
    },
    {
      id: "08",
      text: "CRM",
      link: paths.crm,
    },
    {
      id: "09",
      text: "HRMS",
      link: paths.hrms,
    },
    {
      id: "10",
      text: "Cyber Security Product Development",
      link: paths.cyberSec,
    },
    {
      id: "11",
      text: "CMS",
      link: paths.cms,
    },
  ];

  return (
    <section className="service_landing p_t p_b">
      <div className="content_wrap">
        <h2>What we offer</h2>

        <div className="tab_grid p_top">
          {linkList.map((data) => {
            return (
              <Fragment key={data.id}>
                <NavLink to={data?.link}>
                  <p>
                    {data.id} <span>{data.text}</span>
                  </p>
                  <GoArrowRight />
                </NavLink>
              </Fragment>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ServicesLanding;
