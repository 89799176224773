import { Fragment } from "react";
import "./ServiceProcess.scss";
import { Parallax } from "react-scroll-parallax";

const ServiceProcess = ({ data, title }) => {
  return (
    <Fragment>
      {/* Screen > Tab size */}
      <section
        className="card_stack_sec"
        // style={{ height: `${data.length * 100 + 100}vh` }}
      >
        <div className="content_wrap">
          <h2>{title}</h2>
        </div>

        {data.map((item, k) => {
          return (
            <div
              className="card_blog"
              style={{ zIndex: `${data?.length - k}` }}
              key={item.id}
            >
              <Parallax
                rotate={[data?.length * 1 - 1 * k, -(data?.length * 1 - 1 * k)]}
              >
                <div className="card_screen">
                  <div
                    className="card_box"
                    style={{ transform: `rotate(${-1 - 1 * k}deg)` }}
                  >
                    {item?.icon}
                    <h4>{item?.title}</h4>
                    <p>{item.text}</p>
                  </div>
                </div>
              </Parallax>
            </div>
          );
        })}
      </section>

      {/* Screen <== Tab size */}
      <section className="tab_screen p_t p_b">
        <div className="content_wrap">
          <h2>{title}</h2>

          {data.map((item, k) => {
            return (
              <div className="tab_card_box" key={item?.id}>
                {item?.icon}
                <h4>{item?.title}</h4>
                <p>{item.text}</p>
              </div>
            );
          })}
        </div>
      </section>
    </Fragment>
  );
};

export default ServiceProcess;
