import "../../servicesPages/serviceDetail/serviceMidTab/ServiceMidTab.scss";
import { useContext, useEffect, createRef, useState } from "react";
import WebLayout from "../../components/webLayout/WebLayout";
import { Helmet } from "react-helmet";
import { webContext } from "../../webContext/WebContext";
import { useLocation } from "react-router-dom";
import { domain } from "../../utils/apiData";
import ServiceLanding from "../../servicesPages/serviceDetail/serviceLanding/ServiceLanding";
import { images } from "../../utils/images";
import HomeCta from "../home/homeCta/HomeCta";
import ScrollSpy from "react-scrollspy-navigation";

import ServiceClients from "../../servicesPages/serviceDetail/servicesClients/ServiceClients";
import ServiceTestimonial from "../../servicesPages/serviceDetail/serviceTestimonial/ServiceTestimonial";
import Faq from "../home/Faq/Faq";
import Tools from "../../servicesPages/serviceDetail/tools/Tools";
import { InView } from "react-intersection-observer";

// Expertise Icons
import { TbDeviceDesktopAnalytics } from "react-icons/tb";
import { MdOutlineIntegrationInstructions } from "react-icons/md";
import { TbReportSearch } from "react-icons/tb";
import { MdOutlineBatchPrediction } from "react-icons/md";
import { GrBusinessService } from "react-icons/gr";
import { BiSupport } from "react-icons/bi";

//Benifits Icons
import { BsDatabaseFillCheck } from "react-icons/bs";
import { RiCustomerService2Line } from "react-icons/ri";
import { IoPricetag } from "react-icons/io5";
import { MdOutlineCrisisAlert } from "react-icons/md";
import { FaBrain } from "react-icons/fa";

// Tools Icons
import { FaShop } from "react-icons/fa6";
import { MdOutlineRealEstateAgent } from "react-icons/md";
import { IoRestaurantSharp } from "react-icons/io5";
import { MdOutlinePrecisionManufacturing } from "react-icons/md";
import { GiHealthNormal } from "react-icons/gi";
import { BsPiggyBankFill } from "react-icons/bs";
import { GiTeacher } from "react-icons/gi";

import ServiceOfferings from "../../servicesPages/serviceDetail/serviceOfferings/ServiceOfferings";
import ServiceProcess from "../../servicesPages/serviceDetail/serviceProcess/ServiceProcess";
import StrategiesSec from "../about/strategiesSec/StrategiesSec";
import DataAnaProcess from "./dataAnaProcess/DataAnaProcess";

const DataAnalytics = () => {
  const location = useLocation();
  const [num, setNum] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const goTo = (secId) => {
    const section = document.getElementById(secId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const expertiseData = [
    {
      id: 0,
      img: <TbDeviceDesktopAnalytics />,
      title: "Custom Dashboards & Analytics",
      text: "Visualize critical metrics that matter most to your business. Our dashboards provide real-time insights into performance, helping you spot growth opportunities and optimize revenue streams.",
    },
    {
      id: 1,
      img: <MdOutlineIntegrationInstructions />,
      title: "Advanced Data Integration",
      text: "Combine diverse data sources into a unified Power BI platform. Unlock hidden trends and correlations that drive smarter business strategies.",
    },
    {
      id: 2,
      img: <TbReportSearch />,
      title: "Revenue-Centric Reporting",
      text: "Focus on what fuels your bottom line. Our custom reports highlight key revenue drivers, enabling proactive decision-making and opportunity identification.",
    },
    {
      id: 3,
      img: <MdOutlineBatchPrediction />,
      title: "Predictive Analytics",
      text: "Stay ahead of the curve by leveraging advanced analytics to forecast revenue trends and mitigate risks before they impact your business.",
    },
    {
      id: 4,
      img: <GrBusinessService />,
      title: "Embedded BI Solutions",
      text: "Seamlessly integrate Power BI into your existing tools and platforms to enhance operational efficiency and accelerate decision-making.",
    },
    {
      id: 5,
      img: <BiSupport />,
      title: "Ongoing Optimization & Support",
      text: "Keep your Power BI Infrastructure optimized with dedicated support and continuous enhancements that ensure long-term value.",
    },
  ];

  const faqData = [
    {
      id: 1,
      q: "What is Power BI and how can it help my business?",
      a: "Power BI is a business intelligence tool that transforms raw data into actionable insights. Supagrow’s solutions help businesses improve decision-making, identify growth opportunities, and drive revenue.",
    },
    {
      id: 2,
      q: "What types of dashboards does Supagrow create?",
      a: "We design custom dashboards that provide real-time insights into metrics like sales performance, customer behavior, and operational efficiency tailored to your business goals.",
    },
    {
      id: 3,
      q: "Can Supagrow’s Power BI services integrate with my existing tools?",
      a: "Yes! Our Embedded BI solutions seamlessly integrate with your current tools and platforms to enhance workflows and decision-making.",
    },
    {
      id: 4,
      q: "How do Supagrow’s solutions drive revenue growth?",
      a: "Our revenue-centric reporting and predictive analytics uncover opportunities, forecast trends, and provide insights to improve profitability and operational efficiency.",
    },
    {
      id: 5,
      q: "How do I get started with Supagrow’s Power BI services?",
      a: "Contact us to schedule a consultation. We’ll assess your needs and craft a customized Power BI strategy to transform your data into growth opportunities.",
    },
  ];

  const toolsData = [
    {
      id: 2,
      title: "Retail",
      icon: <FaShop />,
    },
    {
      id: 3,
      title: "Real Estate",
      icon: <MdOutlineRealEstateAgent />,
    },
    {
      id: 4,
      title: "Restaurants",
      icon: <IoRestaurantSharp />,
    },
    {
      id: 5,
      title: "Manufacturing",
      icon: <MdOutlinePrecisionManufacturing />,
    },
    {
      id: 6,
      title: "Healthcare",
      icon: <GiHealthNormal />,
    },
    {
      id: 7,
      title: "Finance",
      icon: <BsPiggyBankFill />,
    },
    {
      id: 8,
      title: "Education",
      icon: <GiTeacher />,
    },
  ];

  const whySupagrow = [
    {
      id: "01",
      title: "Revenue-Focused Approach",
      text: "We design every solution with your bottom line in mind.",
    },
    {
      id: "02",
      title: "Tailored Strategies",
      text: "One-size-fits-all doesn’t work—our services are customized to your industry and goals.",
    },
    {
      id: "03",
      title: "Proven Expertise",
      text: "Our team combines technical prowess with business acumen to deliver impactful results.",
    },
    {
      id: "04",
      title: "Scalable Solutions",
      text: "Grow your BI capabilities as your business evolves without limitations.",
    },
  ];

  const benifits = [
    {
      id: 1,
      icon: <BsDatabaseFillCheck />,
      title: "Data-Driven Growth",
      text: "Make informed decisions based on actionable insights to unlock new revenue streams.",
    },
    {
      id: 2,
      icon: <RiCustomerService2Line />,
      title: "Enhanced Customer Understanding",
      text: "Analyze customer behavior to improve retention and lifetime value.",
    },
    {
      id: 3,
      icon: <IoPricetag />,
      title: "Cost Efficiency",
      text: "Identify areas to reduce overhead and improve profitability.",
    },
    {
      id: 4,
      icon: <MdOutlineCrisisAlert />,
      title: "Sales Optimization",
      text: "Monitor sales trends and adapt strategies for maximum impact.",
    },
    {
      id: 5,
      icon: <FaBrain />,
      title: "Faster Decision-Making",
      text: "Real-time insights mean less waiting and more action.",
    },
  ];

  const processData = [
    {
      id: 0,
      t: "Understanding Business Requirements",
      p: "Collaborate with stakeholders to identify business goals, pain points, and the key metrics to be tracked.",
    },
    {
      id: 1,
      t: "Data Gathering and Preparation",
      p: "Collect data from all relevant sources (ERP, CRM, production systems, etc.) and ensure data quality through cleaning, transformation, and validation.",
    },
    {
      id: 2,
      t: "Dashboard Design and Development",
      p: "Develop custom dashboards tailored to the specific needs of the business, focusing on intuitive visualization and actionable insights.",
    },
    {
      id: 3,
      t: "Implementation and Training",
      p: "Deploy Power BI solutions, integrate them into existing workflows, and train employees on how to effectively use the dashboards for decision-making.",
    },
  ];

  return (
    <WebLayout>
      <Helmet>
        <title>
          Data Analytics Solutions by Supagrow | Drive Growth with Data Insights
        </title>
        <meta
          name="description"
          content="Unlock the potential of your data with Supagrow’s Data Analytics solutions. Transform raw data into actionable insights to fuel revenue growth, enhance efficiency, and make smarter business decisions."
        />
        <link rel="canonical" href={`${domain}${location?.pathname}`} />
      </Helmet>
      <ServiceLanding
        bgImage={images.dataAnalytics}
        overlay={true}
        mainTitle="Fuel Your Revenue Growth with Data-Driven Decisions"
        mainPara1="Leveraging data effectively can be the difference between staying ahead or falling behind. Supagrow’s Power BI services empower businesses to turn raw data into actionable insights-driving revenue growth, operational efficiency, and strategic decision-making."
        mainPara2=""
      />

      <div className="services" id="services">
        <section className="service_mid_tab">
          <div className="content_wrap">
            <div className="md_tab_line">
              <ScrollSpy activeClass="active">
                <div className="service_tab_wraper">
                  <div className="btn_wrap">
                    <button type="button" onClick={() => goTo("secA")}>
                      Expertise
                    </button>

                    <button type="button" onClick={() => goTo("secB")}>
                      Benefits
                    </button>

                    <button type="button" onClick={() => goTo("secC")}>
                      Why&nbsp;Us
                    </button>

                    <button type="button" onClick={() => goTo("secD")}>
                      Clients
                    </button>

                    <button type="button" onClick={() => goTo("secE")}>
                      Review
                    </button>

                    <button type="button" onClick={() => goTo("secF")}>
                      Industries
                    </button>
                    <button type="button" onClick={() => goTo("secG")}>
                      Process
                    </button>

                    <button type="button" onClick={() => goTo("secH")}>
                      FAQ
                    </button>
                  </div>

                  <a
                    href="#secA"
                    className={num === 1 ? "active" : ""}
                    ref={createRef()}
                  >
                    Expertise
                  </a>
                  <a
                    href="#secB"
                    className={num === 2 ? "active" : ""}
                    ref={createRef()}
                  >
                    Benefits
                  </a>

                  <a href="#secC" ref={createRef()}>
                    Why&nbsp;Us
                  </a>
                  <a href="#secD" ref={createRef()}>
                    Clients
                  </a>
                  <a href="#secE" ref={createRef()}>
                    Review
                  </a>

                  <a href="#secF" ref={createRef()}>
                    Industries
                  </a>
                  <a href="#secG" ref={createRef()}>
                    Process
                  </a>
                  <a href="#secH" ref={createRef()}>
                    FAQ
                  </a>
                </div>
              </ScrollSpy>
            </div>
          </div>
        </section>

        <div id="secA">
          <InView
            as="div"
            onChange={(inView, entry) => {
              if (inView) {
                setNum(1);
              }
            }}
          >
            <StrategiesSec
              t1="Our Expertise in Data Analytics"
              para="We specialize in delivering comprehensive Data Analytics solutions tailored to amplify your business potential."
              dataList={expertiseData}
            />
          </InView>
        </div>

        <div id="secB">
          <InView
            as="div"
            onChange={(inView, entry) => {
              if (inView) {
                setNum(2);
              }
            }}
          >
            <ServiceProcess
              data={benifits}
              title="Transforming Business Insights into Action with Power BI"
            />
          </InView>
        </div>

        <div id="secC">
          <InView as="div" onChange={(inView, entry) => setNum(3)}>
            <ServiceOfferings classList="" title="Why Supagrow?" why={whySupagrow} />
          </InView>
        </div>

        <div id="secD">
          <ServiceClients />
        </div>

        <div id="secE">
          <ServiceTestimonial />
        </div>

        <div id="secF">
          <Tools title="Industries We Serve" toolsList={toolsData} />
        </div>

        <div id="secG">
          <DataAnaProcess
            title="Getting started is simple"
            dataList={processData}
          />
        </div>

        <div id="secH">
          <Faq faqData={faqData} />
        </div>
      </div>

      <HomeCta title="Transform your business into a revenue-generating powerhouse." />
    </WebLayout>
  );
};

export default DataAnalytics;
