import "./StrategiesSec.scss";
import PrimaryBtn from "../../../components/primaryBtn/PrimaryBtn";
import { InView } from "react-intersection-observer";
import { Fragment, useContext, useState } from "react";
import ScrollAnimationSection from "../../../components/scrollAnimSec/ScrollAnimSec";
import { webContext } from "../../../webContext/WebContext";



const StrategiesSec = ({dataList, t1, t2, para}) => {
  const [num, setNum] = useState(0);
  const { openContact } = useContext(webContext);

 
  return (
    <section className="strategies p_t">
      <div className="content_wrap">
        <div className="grid_wrap">
          <ScrollAnimationSection>
            <div className="text_wraper">
              <h2>{t1}</h2>
              <h2>{t2}</h2>
              <p>
               {para}
              </p>
              <PrimaryBtn
                varient="dark"
                title="Get in Touch"
                onClick={openContact}
              />
            </div>
          </ScrollAnimationSection>
          <div className="cards_list">
            {dataList?.map((data, j) => {
              return (
                <Fragment key={data.id}>
                  <InView
                    as="div"
                    rootMargin="-50% 0% -50% 0%"
                    onChange={(inView, entry) => setNum(j)}
                  >
                    <div
                      className="testimonial_card"
                      style={{
                        opacity: `${j === num ? "1" : "0.5"}`,
                        transform: `scale(${j === num ? 1 : 0.9})`,
                      }}
                    >
                      {data.img}
                      <h3>{data.title}</h3>
                      <p>{data.text}</p>
                    </div>
                  </InView>
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default StrategiesSec;
