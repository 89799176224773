import logo from "../assets/images/brandlogo.svg";
import logoWhite from "../assets/images/brandlogowhite.svg";
import favicon from "../assets/images/supagrowfav.svg";
import globalMap from "../assets/images/about/map-services.webp";
// Our team
import zayeem from "../assets/images/team/zayeem.webp";
import viraj from "../assets/images/team/viraj.webp";
import ankita from "../assets/images/team/ankita.webp";
import ashish from "../assets/images/team/ashish.webp";
import uttam from "../assets/images/team/uttam.webp";
import raj from "../assets/images/team/raj.jpg";

// tesimonial Profiles
import alistair from "../assets/images/testiProfile/alistair.webp";
import alon from "../assets/images/testiProfile/alon.webp";
import praveena from "../assets/images/testiProfile/praveena.png";
import sanjana from "../assets/images/testiProfile/sanjana.png";
import utsav from "../assets/images/testiProfile/utsav.png";
import rashmika from "../assets/images/testiProfile/rashmika.png";
import upasna from "../assets/images/testiProfile/upasna.jpg";
import keystonLogo from "../assets/images/testiProfile/keyston.svg";

// Clients Logo
import aglow from "../assets/images/clientslogo/aglow.svg";
import secops from "../assets/images/clientslogo/secops.svg";
import sharepass from "../assets/images/clientslogo/sharepass.svg";
import tacticons from "../assets/images/clientslogo/tacticons.svg";
import techdefence from "../assets/images/clientslogo/techdefence.svg";
import worldinformatix from "../assets/images/clientslogo/worldinformatix.svg";
import cybergain from "../assets/images/clientslogo/cybergain.svg";
import cycops from "../assets/images/clientslogo/cycops.svg";
import dwaraka from "../assets/images/clientslogo/dwaraka.svg";
import finneva from "../assets/images/clientslogo/finneva.svg";
import gate8 from "../assets/images/clientslogo/gate8.svg";
import hunter from "../assets/images/clientslogo/hunter.svg";
import inauditus from "../assets/images/clientslogo/inauditus.svg";
import keyston from "../assets/images/clientslogo/keyston.svg";
import livinout from "../assets/images/clientslogo/livinout.svg";
import cybervie from "../assets/images/clientslogo/cybervie.svg";
import farsight from "../assets/images/clientslogo/farsight.svg";
import ionots from "../assets/images/clientslogo/ionots.svg";
import phytives from "../assets/images/clientslogo/phytives.svg";
import sec4cyber from "../assets/images/clientslogo/sec4cyber.svg";
import slimbeez from "../assets/images/clientslogo/slimbeez.svg";
import tfg from "../assets/images/clientslogo/tfg.svg";
import theVoiceBank from "../assets/images/clientslogo/thevoicebank.svg";
import vulnMachine from "../assets/images/clientslogo/vulnmachines.svg";
import masteryInside from "../assets/images/clientslogo/mastery-inside.svg";
import sogo from "../assets/images/clientslogo/sogo.svg";
import symbio from "../assets/images/clientslogo/symbio.svg";
import firstbit from "../assets/images/clientslogo/firstbit.svg";

// About & Services Bg
import aboutA from "../assets/images/about/aboutA.png";
import appBg from "../assets/images/services/app-dev.webp";
import webBg from "../assets/images/services/web-dev.webp";
import uiuxBg from "../assets/images/services/ui-ux.webp";
import serviceBg from "../assets/images/services/serviceBg.webp";
import dataAnalytics from "../assets/images/services/data_analytics.webp";

// Case Studies
import hunterCase from "../assets/images/caseStudies/hunter.webp";
import hunterUi from "../assets/images/caseStudies/hunterUi.webp";

import gate8Case from "../assets/images/caseStudies/caseGate8.webp";
import gate8Ui from "../assets/images/caseStudies/gate8Ui.webp";

import blueBricksCase from "../assets/images/caseStudies/caseBlueBricks.webp";
import blueBricksUi from "../assets/images/caseStudies/blueBricksUi.webp";
import bluebricksLanding from "../assets/images/caseStudies/bluebricks.png";

// Location
import india from "../assets/images/locations/india.gif";
import australia from "../assets/images/locations/australia.gif";
import ireland from "../assets/images/locations/ireland.gif";
import israel from "../assets/images/locations/israel.gif";
import uae from "../assets/images/locations/uae.gif";
import uk from "../assets/images/locations/uk.gif";
import usa from "../assets/images/locations/usa.gif";

import bgBlur from "../assets/images/lfBg.webp";

export const images = {
  logo,
  logoWhite,
  favicon,

  globalMap,

  //Our Team Profile
  zayeem,
  viraj,
  ankita,
  ashish,
  uttam,
  raj,

  // Testimonial
  alistair,
  alon,
  praveena,
  sanjana,
  utsav,
  upasna,
  rashmika,
  keystonLogo,

  // Clients Logo
  aglow,
  cybergain,
  cycops,
  dwaraka,
  finneva,
  gate8,
  hunter,
  inauditus,
  keyston,
  livinout,
  secops,
  sharepass,
  tacticons,
  techdefence,
  worldinformatix,
  cybervie,
  farsight,
  ionots,
  phytives,
  sec4cyber,
  slimbeez,
  tfg,
  theVoiceBank,
  vulnMachine,
  masteryInside,
  sogo,
  symbio,
  firstbit,

  // About
  aboutA,

  // Services Landing Bg
  appBg,
  uiuxBg,
  webBg,
  serviceBg,
  dataAnalytics,

  // Case Studies

  hunterCase,
  hunterUi,

  gate8Case,
  gate8Ui,

  blueBricksCase,
  blueBricksUi,
  bluebricksLanding,

  // location Flag

  india,
  australia,
  ireland,
  israel,
  uae,
  uk,
  usa,

  // Bg
  bgBlur,
};
