import { NavLink, useNavigate } from "react-router-dom";
import "./ServicesDd.scss";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Fragment } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { FaGlobe } from "react-icons/fa6";
import { MdOutlineDesignServices } from "react-icons/md";
import { RiSeoLine } from "react-icons/ri";
import { FaMobileScreen } from "react-icons/fa6";
import { IoAnalyticsOutline } from "react-icons/io5";

import { paths } from "../../../utils/path";

const ServicesDd = () => {
  const navigate = useNavigate();

  const servicesTab = [
    {
      id: 0,
      text: "Web Development",
      icon: <FaGlobe />,
      link:paths.webDev,
    },
    {
      id: 1,
      text: "UI/UX",
      icon: <MdOutlineDesignServices />,
      link:paths.uiux,
    },
    {
      id: 2,
      text: "SEO",
      icon: <RiSeoLine />,
      link:paths.seo,
    },
    {
      id: 3,
      text: "Mobile App Development",
      icon: <FaMobileScreen />,
      link:paths.mobileApp,
    },
    {
      id: 4,
      text: "Data Analytics",
      icon: <IoAnalyticsOutline />,
      link:paths.dataAnalytics,
    },
  ];

  return (
    <div className="nav_dd_sec">
      <div className="des_line">
        <h4>Services</h4>
        <p>
          Our services are designed to meet your needs effectively. We offer a
          comprehensive range of solutions tailored to enhance your business
          operations and achieve your goals. From strategic consulting to
          technical implementation, our expertise ensures that every aspect of
          your project is handled with precision and innovation.
        </p>
        <button
          type="button"
          className="see_more_btn"
          onClick={() => navigate("/services")}
        >
          Explore More <MdKeyboardArrowRight />
        </button>
      </div>
      <div className="tabs_grid">
        {servicesTab.map((data) => {
          return (
            <Fragment key={data.id}>
              <NavLink to={data.link}>
                <p>
                  {data.icon} {data.text}
                </p>
                <IoIosArrowForward />
              </NavLink>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default ServicesDd;
