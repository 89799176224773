import WebLayout from "../../components/webLayout/WebLayout";
import ServiceMidTab from "./serviceMidTab/ServiceMidTab";
import ServiceLanding from "./serviceLanding/ServiceLanding";
import HomeCta from "../../pages/home/homeCta/HomeCta";
import { paths } from "../../utils/path";
import "../../../node_modules/bootstrap/js/src/scrollspy";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { domain } from "../../utils/apiData";
import { images } from "../../utils/images";

const ServiceDetail = () => {
  
  const location = useLocation();
  // Landing Data
  const landingData = [
    {
      id: paths.webDev,
      bgImg: images.webBg,
      overlay: true,
      mainTitle: "Web Development",
      mainPara1:
        "Boost Your Online Presence with Supagrow's Expert Web Development Services!",
      mainPara2:
        "In the dynamic digital realm, Supagrow emerges as the premier web development force, reshaping industry benchmarks. Our adept team combines skills and cutting-edge tech to fuel unprecedented growth for your business. Committed to the latest trends, we excel in responsive design, optimized performance, and robust functionality.",
    },
    {
      id: paths.uiux,
      bgImg: images.uiuxBg,
      overlay: true,
      mainTitle: "User-Centric UI/UX Design",
      mainPara1: "Boost Your Online Presence with Supagrow's UI/UX Services!",
      mainPara2:
        "Dedicated to the field of UI/UX design, our team is focused on enhancing user experiences through thorough research and creative, user-centered methods. We intricately outline every aspect of the user's journey, ensuring a precise fit with the diverse requirements of our target audience.",
    },
    {
      id: paths.seo,
      bgImg: images.serviceBg,
      overlay: false,
      mainTitle: "Convert Clicks to Customers",
      mainPara1:
        "Boost rankings, attract qualified traffic, and watch your sales and leads soar.",
      mainPara2:
        "Playing a pivotal role in elevating organic visibility at a remarkably lower cost, SEO is a fundamental process for boosting organic sales and increasing website traffic. At Supagrow, we undertake a comprehensive analysis to pinpoint elements that may hinder your website from reaching its full potential.",
    },
    {
      id: paths.mobileApp,
      bgImg: images.appBg,
      overlay: true,
      mainTitle: "Mobile App Development",
      mainPara1:
        "Step into the future of mobile innovation with Supagrow's App Development Services, meticulously crafted using our UI/UX process.",
      mainPara2:
        "Our expert team blends creativity and cutting-edge technology to bring your digital ideas to life with dynamic and user-friendly mobile applications. From concept to deployment, we prioritize seamless functionality and captivating design, ensuring an engaging user experience.",
    },
    {
      id: paths.ecom,
      bgImg: images.serviceBg,
      overlay: false,
      mainTitle: "E-commerce Development",
      mainPara1:
        "Discover E-commerce excellence with Supagrow's streamlined UI/UX-focused Website Development.",
      mainPara2:
        "Elevate your online store with our seamless integration of cutting-edge technology, ensuring visually stunning and user-centric platforms. From initial design concepts to the final interface, we prioritize an engaging user experience at every step.",
    },
    {
      id: paths.lms,
      bgImg: images.serviceBg,
      overlay: false,
      mainTitle: "Custom LMS Development",
      mainPara1:
        "Tired of the limitations posed by your current Learning Management System (LMS)?",
      mainPara2:
        "Supagrow understands the challenges – from outdated features to inflexible interfaces hindering the learning experience. Managing educational content efficiently is critical, and that's where our LMS Development Services step in.",
    },
    {
      id: paths.crm,
      bgImg: images.serviceBg,
      overlay: false,
      mainTitle: "Streamlined CRM Solutions",
      mainPara1:
        "Respond to customer expectations swiftly, or risk losing them in today's rapidly evolving digital landscape.",
      mainPara2:
        "In a dynamic world of marketing and digitally segmented customers, Supagrow empowers you to establish clear connections, redefine customer relationships, and imbue a customer-friendly touch across all facets of your business.",
    },
    {
      id: paths.hrms,
      bgImg: images.serviceBg,
      overlay: false,
      mainTitle: "HRMS Solutions",
      mainPara1:
        "Efficiently manage your human capital with our advanced HRMS solutions, tailored to optimize HR processes and empower your team.",
      mainPara2:
        "At your company, we redefine HR management by combining efficiency with innovation, ensuring your workforce thrives. Discover how our advanced technology transforms HR into a strategic asset, empowering your team to focus on what matters most—achieving your business goals.",
    },
    {
      id: paths.cyberSec,
      bgImg: images.serviceBg,
      overlay: false,
      mainTitle: "Cyber Security Product Development",
      mainPara1:
        "Boost Your Online Presence with Supagrow's Expert Web Development Services!",
      mainPara2:
        "In the dynamic digital realm, Supagrow emerges as the premier web development force, reshaping industry benchmarks. Our adept team combines skills and cutting-edge tech to fuel unprecedented growth for your business. Committed to the latest trends, we excel in responsive design, optimized performance, and robust functionality.",
    },
    {
      id: paths.cms,
      bgImg: images.serviceBg,
      overlay: false,
      mainTitle: "Efficient CMS Solutions",
      mainPara1:
        "Tired of struggling with outdated and inflexible content management systems? Supagrow understands the pain points you face in managing your content efficiently",
      mainPara2:
        "Enter Supagrow's CMS Development Services, your tailored solution to content management challenges. Our expert team addresses these pain points head-on by leveraging cutting-edge technology to build a customized Content Management System (CMS) designed around your specific needs. Say goodbye to rigid interfaces and workflow inefficiencies.",
    },  
  ];

  const landingTarget = () => {
    return landingData?.find((obj) => obj.id === location.pathname);
  };

  const data = [
    {
      id: paths.webDev,
      mainTitle: "Best Custom Website Development Services in India | Supagrow",
      description:
        "Custom Website Development Services for Ecommerce, Cyber Security, Real Estate and much more, get Laravel, Php website etc built for your business today with Supagrow, Visit Now!",
      cta: "We deliver our best to make your product better.",
    },
    {
      id: paths.uiux,
      mainTitle: "UI/UX Design Company | UI/UX Development Services | Supagrow",
      description:
        "Explore our UI/UX design services for seamless user experiences. We're a trusted company dedicated to crafting simple and effective solutions for your digital needs.",
      cta: "We deliver our best to make your product better.",
    },
    {
      id: paths.seo,
      mainTitle: "Best On-Page and Off-Page SEO services in India | SupaGrow",
      description:
        "Boost your website's visibility and rankings with our top-notch on-page and off-page SEO services. Get in touch with SupaGrow today for expert guidance and guaranteed results.",
      cta: "We deliver our best to make your product better.",
    },
    {
      id: paths.mobileApp,
      mainTitle:
        "Custom Mobile App Development Services In India | Android App Development",
      description:
        "Get tailored mobile app development services in India. We specialize in Android app development, creating solutions that cater to your unique needs.",
      cta: "We deliver our best to make your product better.",
    },
    {
      id: paths.ecom,
      mainTitle:
        "E-Commerce Website And Software Development In India | SupaGrow",
      description:
        "Empower your business with SupaGrow's E-commerce website and software development solutions in India. Elevate your online presence and drive growth.",
      cta: "We deliver our best to make your product better.",
    },
    {
      id: paths.lms,
      mainTitle:
        "Educational Software and Website Development |LMS Development| SupaGrow",
      description:
        "Empower education through SupaGrow's software and website development. Transform learning experiences with tailored solutions for educational institutions.",
      cta: "We deliver our best to make your product better.",
    },
    {
      id: paths.crm,
      mainTitle: "CRM Software Development Company In India | SupaGrow",
      description:
        "Your Trusted CRM Software Development Partner in India. Unlock Enhanced Customer Relationships and Efficiency with Our Custom Solutions. Visit now!",
      cta: "We deliver our best to make your product better.",
    },
    {
      id: paths.hrms,
      overlay: false,
      mainTitle: "Best Software Development Solutions for HR | SupaGrow",
      description:
        "Discover top-notch HR software development solutions by SupaGrow. Optimize your human resources processes with our customized software for enhanced efficiency.",
      cta: "We deliver our best to make your product better.",
    },
    {
      id: paths.cyberSec,
      mainTitle: "Cyber Security Web Development And designing | SupaGrow",
      description:
        "Secure your digital landscape with SupaGrow's cyber security web development and designing. Protect your data and users with cutting-edge solutions.",
      cta: "We deliver our best to make your product better.",
    },
    {
      id: paths.cms,
      mainTitle: "Custom CMS Development | CMS Development Services | SupaGrow",
      description:
        "Experience efficient content management with SupaGrow's custom CMS development services. Streamline your online presence for better engagement and control.",
      cta: "We deliver our best to make your product better.",
    },
  ];


  const target = () => {
    return data.find((obj) => obj.id === location.pathname);
  };

  return (
    <WebLayout>
      <Helmet>
        <title>{target()?.mainTitle}</title>
        <meta name="description" content={target()?.description} />
        <link rel="canonical" href={`${domain}${location?.pathname}`} />
      </Helmet>

      <ServiceLanding
        bgImage={landingTarget()?.bgImg}
        overlay={landingTarget()?.overlay}
        mainTitle={landingTarget()?.mainTitle}
        mainPara1={landingTarget()?.mainPara1}
        mainPara2={landingTarget()?.mainPara2}
      />
      <ServiceMidTab />

      <HomeCta title={target()?.cta} />
    </WebLayout>
  );
};

export default ServiceDetail;
