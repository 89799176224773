import { Fragment, useEffect, useRef, useState } from "react";
import ScrollAnimationSection from "../../../components/scrollAnimSec/ScrollAnimSec";
import "./DataAnaProcess.scss";
import { InView } from "react-intersection-observer";
import useScrollPosition from "../../../utils/hooks/useScrollPosition";

const DataAnaProcess = ({ title, dataList }) => {
  const [num, setNum] = useState(0);

  const sectionRef = useRef(null);
  const [distance, setDistance] = useState(0);

  const calculateDistance = () => {
    if (sectionRef.current) {
      const currentDistance =
        sectionRef.current.getBoundingClientRect().top + window.scrollY;
      setDistance(currentDistance);
    }
  };

  useEffect(() => {
    calculateDistance();
    const handleResize = () => {
      calculateDistance();
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const S = useScrollPosition();

  return (
    <section className="data_analytics_process p_b" ref={sectionRef}>
      <div
        className="v_tab_line"
        style={{
          height: `${S + 400 - distance > 0 ? S + 400 - distance : 0}px`,
        }}
      ></div>


      

      <div className="content_wrap">
        <ScrollAnimationSection>
          <h2>{title}</h2>
        </ScrollAnimationSection>

        <div className="process_list">
          {dataList?.map((data, k) => {
            return (
              <Fragment key={data?.id}>
                <InView
                  as="div"
                  rootMargin="-50% 0% -50% 0%"
                  onChange={(inView, entry) => setNum(k + 1)}
                >
                  <div className="process_flap">
                    <div
                      className={`flap ${num === k + 1 ? "active" : ""}`}
                    ></div>
                    <h3>{data?.t}</h3>
                    <p>{data?.p} </p>
                  </div>
                </InView>
              </Fragment>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default DataAnaProcess;
