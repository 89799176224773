//icons
import { FaListUl } from "react-icons/fa";
import { IoAnalyticsSharp } from "react-icons/io5";
import { MdOutlineDesignServices } from "react-icons/md";
import { FaLaptopCode } from "react-icons/fa";
import { SiTestcafe } from "react-icons/si";
import { MdOutlineRocketLaunch } from "react-icons/md";
import { HiDocumentSearch } from "react-icons/hi";
import { RiSeoLine } from "react-icons/ri";
import { MdArchitecture } from "react-icons/md";
import { MdFindInPage } from "react-icons/md";
import { GrOptimize } from "react-icons/gr";
import { MdContentPasteSearch } from "react-icons/md";
import { FaLink } from "react-icons/fa6";

import { paths } from "./path";

// Tools
import { SiReact } from "react-icons/si";
import { IoLogoAngular } from "react-icons/io";
import { FaNodeJs } from "react-icons/fa6";
import { FaLaravel } from "react-icons/fa";
import { FaPhp } from "react-icons/fa6";
import { FaWordpress } from "react-icons/fa";
import { SiMongodb } from "react-icons/si";
import { DiMysql } from "react-icons/di";

import { IoLogoFigma } from "react-icons/io5";
import { SiAdobexd } from "react-icons/si";
import { SiMiro } from "react-icons/si";
import { SiMarvelapp } from "react-icons/si";
import { FaSketch } from "react-icons/fa6";
import { SiAdobephotoshop } from "react-icons/si";
import { SiAdobeillustrator } from "react-icons/si";

// SEO
import { SiGoogleanalytics } from "react-icons/si";
import { SiGooglesearchconsole } from "react-icons/si";
import { SiGoogletagmanager } from "react-icons/si";
import { SiSemrush } from "react-icons/si";

// Mobile App
import { AiFillAndroid } from "react-icons/ai";
import { SiFlutter } from "react-icons/si";

export const servicesData = [
  // Services
  {
    slug: paths.webDev,
    process: {
      title: "From Concept to Creation",
      list: [
        {
          id: 1,
          icon: <FaListUl />,
          title: "Planning",
          text: "Brainstorming of initial structure according to requirements and creating wireframes.",
        },
        {
          id: 2,
          icon: <IoAnalyticsSharp />,
          title: "Analysis",
          text: "Doing market research and analysing things which can be a valuable part of the project. Including customer journey and user experience.",
        },
        {
          id: 3,
          icon: <MdOutlineDesignServices />,
          title: "Design",
          text: "Designing of the finalised structure.",
        },
        {
          id: 4,
          icon: <FaLaptopCode />,
          title: "Implementation",
          text: "Development of the project with functionalities and integrations.",
        },
        {
          id: 5,
          icon: <SiTestcafe />,
          title: "Testing",
          text: "Testing of developed products through QA Team.",
        },
        {
          id: 6,
          icon: <MdOutlineRocketLaunch />,
          title: "Review & Migration",
          text: "Taking valuable inputs from clients before final migration. Once approved, the migrating project is on the client's server.",
        },
      ],
    },
    faqList: [
      {
        id: 1,
        q: "What custom web development services do you offer?",
        a: "We specialize in custom web applications, e-commerce solutions, and CMS-based websites, tailored to meet your unique business requirements.",
      },
      {
        id: 2,
        q: "How do you ensure the quality of your web development projects?",
        a: "Our team follows a rigorous quality assurance process, including code reviews, testing protocols, and user feedback, to ensure optimal performance and security.",
      },
      {
        id: 3,
        q: "Can you integrate third-party tools and APIs into my website?",
        a: "Yes, we have extensive experience integrating various third-party tools and APIs to enhance functionality, from payment gateways to CRM systems.",
      },
      {
        id: 4,
        q: "What kind of support do you provide post-launch?",
        a: "We offer comprehensive post-launch support, including maintenance, updates, and troubleshooting to ensure your website continues to perform at its best.",
      },
      {
        id: 5,
        q: "How do you approach responsive web design?",
        a: "We implement a mobile-first design strategy, ensuring that your website provides an optimal experience across all devices and screen sizes.",
      },
    ],
    tools: [
      {
        id: 0,
        title: "React",
        icon: <SiReact />,
      },
      {
        id: 1,
        title: "Angular",
        icon: <IoLogoAngular />,
      },
      {
        id: 2,
        title: "Node",
        icon: <FaNodeJs />,
      },
      {
        id: 3,
        title: "Laravel",
        icon: <FaLaravel />,
      },
      {
        id: 4,
        title: "Php",
        icon: <FaPhp />,
      },
      {
        id: 5,
        title: "Mongodb",
        icon: <SiMongodb />,
      },
      {
        id: 6,
        title: "Sql",
        icon: <DiMysql />,
      },
    ],
  },

  {
    slug: paths.uiux,
    process: {
      title: "From Concept to Creation",
      list: [
        {
          id: 1,
          icon: <HiDocumentSearch />,
          title: "Discovery & Research",
          text: "Gather insights from stakeholders and conduct user research to understand target audience needs and behaviors. Analyze competitors to identify industry standards and opportunities. This ensures alignment with business objectives and user expectations.",
        },
        {
          id: 2,
          icon: <MdArchitecture />,
          title: "Define",
          text: "Create user personas, user stories, and scenarios to represent the target audience and their interactions. Organize content with information architecture to ensure intuitive navigation. This step clarifies user needs and structures the design effectively.",
        },
        {
          id: 3,
          icon: <RiSeoLine />,
          title: "Ideation",
          text: "Generate ideas through brainstorming, create low-fidelity wireframes to outline structure, and sketch concepts for rapid iteration. This phase encourages creativity and helps visualize potential solutions.",
        },
        {
          id: 4,
          icon: <MdOutlineDesignServices />,
          title: "Design",
          text: "Create high-fidelity prototypes, define visual elements (color, typography), and establish a consistent design system. This phase ensures a visually appealing and functional interface aligned with brand identity.",
        },
        {
          id: 5,
          icon: <SiTestcafe />,
          title: "Testing",
          text: "Conduct usability tests, A/B tests, and iterative testing to gather feedback and refine the design. This phase ensures the interface meets user expectations and optimizes user experience.",
        },
        {
          id: 6,
          icon: <FaLaptopCode />,
          title: "Development Handoff",
          text: "Deliver detailed design specifications and assets to facilitate accurate implementation, ensuring seamless transition from design to development.",
        },
      ],
    },
    faqList: [
      {
        id: 1,
        q: "What is your approach to user-centered design?",
        a: "We prioritize user needs through research, prototyping, and usability testing, ensuring the final design aligns with user expectations and business goals",
      },
      {
        id: 2,
        q: "How do you incorporate feedback into your UI/UX design process?",
        a: "We actively seek user feedback through surveys, interviews, and usability testing, allowing us to iterate and improve designs based on real user insights.",
      },
      {
        id: 3,
        q: "Can you create UI/UX designs for both web and mobile applications?",
        a: "Yes, we design seamless UI/UX experiences across both web and mobile platforms, ensuring consistency and usability throughout.",
      },
      {
        id: 4,
        q: "What tools do you use for UI/UX design?",
        a: "We utilize industry-standard tools like Figma, Adobe XD, and Sketch for design, along with prototyping tools to create interactive wireframes.",
      },
      {
        id: 5,
        q: "How do you ensure accessibility in your designs?",
        a: "We follow WCAG guidelines and conduct accessibility audits to ensure our designs are inclusive and usable for all users, including those with disabilities.",
      },
    ],
    tools: [
      {
        id: 0,
        title: "Figma",
        icon: <IoLogoFigma />,
      },
      {
        id: 1,
        title: "Adobexd",
        icon: <SiAdobexd />,
      },
      {
        id: 2,
        title: "Miro",
        icon: <SiMiro />,
      },
      {
        id: 3,
        title: "Marvel",
        icon: <SiMarvelapp />,
      },
      {
        id: 4,
        title: "Sketch",
        icon: <FaSketch />,
      },
      {
        id: 5,
        title: "Photoshop",
        icon: <SiAdobephotoshop />,
      },
      {
        id: 6,
        title: "Illustrator",
        icon: <SiAdobeillustrator />,
      },
    ],
  },

  {
    slug: paths.seo,
    process: {
      title: "From Concept to Creation",
      list: [
        {
          id: 1,
          icon: <MdFindInPage />,
          title: "Keyword Research",
          text: "Identify relevant keywords for your content based on search volume, competition, and relevance to your audience.",
        },
        {
          id: 2,
          icon: <GrOptimize />,
          title: "On-Page Optimization",
          text: "Optimize your web pages for your chosen keywords. This includes using keywords in titles, meta descriptions, headers, and throughout the content naturally. Improve loading speed, mobile-friendliness, and ensure your site is easy to navigate.",
        },
        {
          id: 3,
          icon: <MdContentPasteSearch />,
          title: "Content Creation",
          text: "Develop high-quality, valuable content that addresses the needs of your target audience. This could be blog posts, articles, videos, or infographics.",
        },
        {
          id: 4,
          icon: <FaLink />,
          title: "Link Building",
          text: "Acquire backlinks from reputable websites to improve your site's authority. Focus on natural link building through guest posting, partnerships, and content promotion.",
        },
        {
          id: 5,
          icon: <FaLaptopCode />,
          title: "Monitoring and Adjusting",
          text: "Regularly monitor your SEO performance using tools like Google Analytics and Search Console. Adjust your strategy based on insights and algorithm updates to maintain and improve your rankings over time.",
        },
      ],
    },
    faqList: [
      {
        id: 1,
        q: "What sets your SEO strategy apart from others?",
        a: "Our SEO strategy is data-driven, focusing on tailored keyword research, in-depth competitor analysis, and continuous optimization based on industry trends.",
      },
      {
        id: 2,
        q: "How do you measure the success of your SEO efforts?",
        a: "We track key performance indicators (KPIs) such as organic traffic, search rankings, and conversion rates, providing regular reports to demonstrate progress.",
      },
      {
        id: 3,
        q: "Do you offer localized SEO services for businesses targeting specific regions?",
        a: "Yes, we specialize in localized SEO strategies, helping businesses improve visibility in their target markets and drive relevant traffic to their sites.",
      },
      {
        id: 4,
        q: "What role does content play in your SEO strategy?",
        a: "Content is critical; we develop high-quality, engaging content that aligns with SEO best practices to boost rankings and attract organic traffic.",
      },
      {
        id: 5,
        q: "How often do you recommend updating an SEO strategy?",
        a: "SEO is an ongoing process. We recommend reviewing and updating your strategy every 3-6 months to adapt to algorithm changes and market trends.",
      },
    ],
    tools: [
      {
        id: 0,
        title: "Googlean Alytics",
        icon: <SiGoogleanalytics />,
      },
      {
        id: 1,
        title: "Google Tag Manager",
        icon: <SiGoogletagmanager />,
      },
      {
        id: 2,
        title: "Google Search Console",
        icon: <SiGooglesearchconsole />,
      },
      {
        id: 3,
        title: "Semrush",
        icon: <SiSemrush />,
      },
    ],
  },

  {
    slug: paths.mobileApp,
    process: {
      title: "From Concept to Creation",
      list: [
        {
          id: 1,
          icon: <FaListUl />,
          title: "Planning",
          text: "Brainstorming of initial structure according to requirements and creating wireframes.",
        },
        {
          id: 2,
          icon: <IoAnalyticsSharp />,
          title: "Analysis",
          text: "Doing market research and analysing things which can be a valuable part of the project. Including customer journey and user experience.",
        },
        {
          id: 3,
          icon: <MdOutlineDesignServices />,
          title: "Design",
          text: "Designing of the finalised structure.",
        },
        {
          id: 4,
          icon: <FaLaptopCode />,
          title: "Implementation",
          text: "Development of the project with functionalities and integrations.",
        },
        {
          id: 5,
          icon: <SiTestcafe />,
          title: "Testing",
          text: "Testing of developed products through QA Team.",
        },
        {
          id: 6,
          icon: <MdOutlineRocketLaunch />,
          title: "Review & Migration",
          text: "Taking valuable inputs from clients before final migration. Once approved, the migrating project is on the client's server.",
        },
      ],
    },
    faqList: [
      {
        id: 1,
        q: "What is your app development process?",
        a: "Our process includes discovery, design, development, testing, and deployment, ensuring a comprehensive approach to creating high-quality mobile applications.",
      },
      {
        id: 2,
        q: "Do you offer both native and hybrid app development?",
        a: "Yes, we provide both native and hybrid app development solutions, selecting the best approach based on your project goals and target audience.",
      },
      {
        id: 3,
        q: "How do you handle app security during development?",
        a: "We implement best practices in app security, including data encryption, secure API integrations, and regular security audits throughout the development process.",
      },
      {
        id: 4,
        q: "What are the typical costs involved in mobile app development?",
        a: "Costs vary based on complexity, features, and platforms. We provide a detailed estimate after assessing your project requirements during the consultation phase.",
      },
      {
        id: 5,
        q: "How do you support app scalability and future updates?",
        a: "We design our apps with scalability in mind, allowing for easy integration of new features and updates as your business grows and evolves.",
      },
    ],
    tools: [
      {
        id: 0,
        title: "Android",
        icon: <AiFillAndroid />,
      },
      {
        id: 1,
        title: "Flutter",
        icon: <SiFlutter />,
      },
      {
        id: 2,
        title: "React Native",
        icon: <SiReact />,
      },
      {
        id: 3,
        title: "Node",
        icon: <FaNodeJs />,
      },
      {
        id: 4,
        title: "Mongodb",
        icon: <SiMongodb />,
      },
      {
        id: 5,
        title: "sql",
        icon: <DiMysql />,
      },
      {
        id: 6,
        title: "Laravel",
        icon: <FaLaravel />,
      },
    ],
  },
  // Solutions
  {
    slug: paths.ecom,
    process: {
      title: "From Concept to Creation",
      list: [
        {
          id: 1,
          icon: <FaListUl />,
          title: "Planning",
          text: "Brainstorming of initial structure according to requirements and creating wireframes.",
        },
        {
          id: 2,
          icon: <IoAnalyticsSharp />,
          title: "Analysis",
          text: "Doing market research and analysing things which can be a valuable part of the project. Including customer journey and user experience.",
        },
        {
          id: 3,
          icon: <MdOutlineDesignServices />,
          title: "Design",
          text: "Designing of the finalised structure.",
        },
        {
          id: 4,
          icon: <FaLaptopCode />,
          title: "Implementation",
          text: "Development of the project with functionalities and integrations.",
        },
        {
          id: 5,
          icon: <SiTestcafe />,
          title: "Testing",
          text: "Testing of developed products through QA Team.",
        },
        {
          id: 6,
          icon: <MdOutlineRocketLaunch />,
          title: "Review & Migration",
          text: "Taking valuable inputs from clients before final migration. Once approved, the migrating project is on the client's server.",
        },
      ],
    },
    faqList: [
      {
        id: 1,
        q: "What custom web development services do you offer?",
        a: "We specialize in custom web applications, e-commerce solutions, and CMS-based websites, tailored to meet your unique business requirements.",
      },
      {
        id: 2,
        q: "How do you ensure the quality of your web development projects?",
        a: "Our team follows a rigorous quality assurance process, including code reviews, testing protocols, and user feedback, to ensure optimal performance and security.",
      },
      {
        id: 3,
        q: "Can you integrate third-party tools and APIs into my website?",
        a: "Yes, we have extensive experience integrating various third-party tools and APIs to enhance functionality, from payment gateways to CRM systems.",
      },
      {
        id: 4,
        q: "What kind of support do you provide post-launch?",
        a: "We offer comprehensive post-launch support, including maintenance, updates, and troubleshooting to ensure your website continues to perform at its best.",
      },
      {
        id: 5,
        q: "How do you approach responsive web design?",
        a: "We implement a mobile-first design strategy, ensuring that your website provides an optimal experience across all devices and screen sizes.",
      },
    ],
    tools: [
      {
        id: 0,
        title: "React",
        icon: <SiReact />,
      },
      {
        id: 1,
        title: "Angular",
        icon: <IoLogoAngular />,
      },
      {
        id: 2,
        title: "Node",
        icon: <FaNodeJs />,
      },
      {
        id: 3,
        title: "Laravel",
        icon: <FaLaravel />,
      },
      {
        id: 4,
        title: "Php",
        icon: <FaPhp />,
      },
      {
        id: 5,
        title: "Mongodb",
        icon: <SiMongodb />,
      },
      {
        id: 6,
        title: "Sql",
        icon: <DiMysql />,
      },
    ],
  },
  {
    slug: paths.lms,
    process: {
      title: "From Concept to Creation",
      list: [
        {
          id: 1,
          icon: <FaListUl />,
          title: "Planning",
          text: "Brainstorming of initial structure according to requirements and creating wireframes.",
        },
        {
          id: 2,
          icon: <IoAnalyticsSharp />,
          title: "Analysis",
          text: "Doing market research and analysing things which can be a valuable part of the project. Including customer journey and user experience.",
        },
        {
          id: 3,
          icon: <MdOutlineDesignServices />,
          title: "Design",
          text: "Designing of the finalised structure.",
        },
        {
          id: 4,
          icon: <FaLaptopCode />,
          title: "Implementation",
          text: "Development of the project with functionalities and integrations.",
        },
        {
          id: 5,
          icon: <SiTestcafe />,
          title: "Testing",
          text: "Testing of developed products through QA Team.",
        },
        {
          id: 6,
          icon: <MdOutlineRocketLaunch />,
          title: "Review & Migration",
          text: "Taking valuable inputs from clients before final migration. Once approved, the migrating project is on the client's server.",
        },
      ],
    },
    faqList: [
      {
        id: 1,
        q: "What custom web development services do you offer?",
        a: "We specialize in custom web applications, e-commerce solutions, and CMS-based websites, tailored to meet your unique business requirements.",
      },
      {
        id: 2,
        q: "How do you ensure the quality of your web development projects?",
        a: "Our team follows a rigorous quality assurance process, including code reviews, testing protocols, and user feedback, to ensure optimal performance and security.",
      },
      {
        id: 3,
        q: "Can you integrate third-party tools and APIs into my website?",
        a: "Yes, we have extensive experience integrating various third-party tools and APIs to enhance functionality, from payment gateways to CRM systems.",
      },
      {
        id: 4,
        q: "What kind of support do you provide post-launch?",
        a: "We offer comprehensive post-launch support, including maintenance, updates, and troubleshooting to ensure your website continues to perform at its best.",
      },
      {
        id: 5,
        q: "How do you approach responsive web design?",
        a: "We implement a mobile-first design strategy, ensuring that your website provides an optimal experience across all devices and screen sizes.",
      },
    ],
    tools: [
      {
        id: 0,
        title: "React",
        icon: <SiReact />,
      },
      {
        id: 1,
        title: "Angular",
        icon: <IoLogoAngular />,
      },
      {
        id: 2,
        title: "Node",
        icon: <FaNodeJs />,
      },
      {
        id: 3,
        title: "Laravel",
        icon: <FaLaravel />,
      },
      {
        id: 4,
        title: "Php",
        icon: <FaPhp />,
      },
      {
        id: 5,
        title: "Mongodb",
        icon: <SiMongodb />,
      },
      {
        id: 6,
        title: "Sql",
        icon: <DiMysql />,
      },
    ],
  },
  {
    slug: paths.crm,
    process: {
      title: "From Concept to Creation",
      list: [
        {
          id: 1,
          icon: <FaListUl />,
          title: "Planning",
          text: "Brainstorming of initial structure according to requirements and creating wireframes.",
        },
        {
          id: 2,
          icon: <IoAnalyticsSharp />,
          title: "Analysis",
          text: "Doing market research and analysing things which can be a valuable part of the project. Including customer journey and user experience.",
        },
        {
          id: 3,
          icon: <MdOutlineDesignServices />,
          title: "Design",
          text: "Designing of the finalised structure.",
        },
        {
          id: 4,
          icon: <FaLaptopCode />,
          title: "Implementation",
          text: "Development of the project with functionalities and integrations.",
        },
        {
          id: 5,
          icon: <SiTestcafe />,
          title: "Testing",
          text: "Testing of developed products through QA Team.",
        },
        {
          id: 6,
          icon: <MdOutlineRocketLaunch />,
          title: "Review & Migration",
          text: "Taking valuable inputs from clients before final migration. Once approved, the migrating project is on the client's server.",
        },
      ],
    },
    faqList: [
      {
        id: 1,
        q: "What custom web development services do you offer?",
        a: "We specialize in custom web applications, e-commerce solutions, and CMS-based websites, tailored to meet your unique business requirements.",
      },
      {
        id: 2,
        q: "How do you ensure the quality of your web development projects?",
        a: "Our team follows a rigorous quality assurance process, including code reviews, testing protocols, and user feedback, to ensure optimal performance and security.",
      },
      {
        id: 3,
        q: "Can you integrate third-party tools and APIs into my website?",
        a: "Yes, we have extensive experience integrating various third-party tools and APIs to enhance functionality, from payment gateways to CRM systems.",
      },
      {
        id: 4,
        q: "What kind of support do you provide post-launch?",
        a: "We offer comprehensive post-launch support, including maintenance, updates, and troubleshooting to ensure your website continues to perform at its best.",
      },
      {
        id: 5,
        q: "How do you approach responsive web design?",
        a: "We implement a mobile-first design strategy, ensuring that your website provides an optimal experience across all devices and screen sizes.",
      },
    ],
    tools: [
      {
        id: 0,
        title: "React",
        icon: <SiReact />,
      },
      {
        id: 1,
        title: "Angular",
        icon: <IoLogoAngular />,
      },
      {
        id: 2,
        title: "Node",
        icon: <FaNodeJs />,
      },
      {
        id: 3,
        title: "Laravel",
        icon: <FaLaravel />,
      },
      {
        id: 4,
        title: "Php",
        icon: <FaPhp />,
      },
      {
        id: 5,
        title: "Mongodb",
        icon: <SiMongodb />,
      },
      {
        id: 6,
        title: "Sql",
        icon: <DiMysql />,
      },
    ],
  },
  {
    slug: paths.hrms,
    process: {
      title: "From Concept to Creation",
      list: [
        {
          id: 1,
          icon: <FaListUl />,
          title: "Planning",
          text: "Brainstorming of initial structure according to requirements and creating wireframes.",
        },
        {
          id: 2,
          icon: <IoAnalyticsSharp />,
          title: "Analysis",
          text: "Doing market research and analysing things which can be a valuable part of the project. Including customer journey and user experience.",
        },
        {
          id: 3,
          icon: <MdOutlineDesignServices />,
          title: "Design",
          text: "Designing of the finalised structure.",
        },
        {
          id: 4,
          icon: <FaLaptopCode />,
          title: "Implementation",
          text: "Development of the project with functionalities and integrations.",
        },
        {
          id: 5,
          icon: <SiTestcafe />,
          title: "Testing",
          text: "Testing of developed products through QA Team.",
        },
        {
          id: 6,
          icon: <MdOutlineRocketLaunch />,
          title: "Review & Migration",
          text: "Taking valuable inputs from clients before final migration. Once approved, the migrating project is on the client's server.",
        },
      ],
    },
    faqList: [
      {
        id: 1,
        q: "What custom web development services do you offer?",
        a: "We specialize in custom web applications, e-commerce solutions, and CMS-based websites, tailored to meet your unique business requirements.",
      },
      {
        id: 2,
        q: "How do you ensure the quality of your web development projects?",
        a: "Our team follows a rigorous quality assurance process, including code reviews, testing protocols, and user feedback, to ensure optimal performance and security.",
      },
      {
        id: 3,
        q: "Can you integrate third-party tools and APIs into my website?",
        a: "Yes, we have extensive experience integrating various third-party tools and APIs to enhance functionality, from payment gateways to CRM systems.",
      },
      {
        id: 4,
        q: "What kind of support do you provide post-launch?",
        a: "We offer comprehensive post-launch support, including maintenance, updates, and troubleshooting to ensure your website continues to perform at its best.",
      },
      {
        id: 5,
        q: "How do you approach responsive web design?",
        a: "We implement a mobile-first design strategy, ensuring that your website provides an optimal experience across all devices and screen sizes.",
      },
    ],
    tools: [
      {
        id: 0,
        title: "React",
        icon: <SiReact />,
      },
      {
        id: 1,
        title: "Angular",
        icon: <IoLogoAngular />,
      },
      {
        id: 2,
        title: "Node",
        icon: <FaNodeJs />,
      },
      {
        id: 3,
        title: "Laravel",
        icon: <FaLaravel />,
      },
      {
        id: 4,
        title: "Php",
        icon: <FaPhp />,
      },
      {
        id: 5,
        title: "Mongodb",
        icon: <SiMongodb />,
      },
      {
        id: 6,
        title: "Sql",
        icon: <DiMysql />,
      },
    ],
  },
  {
    slug: paths.cyberSec,
    process: {
      title: "From Concept to Creation",
      list: [
        {
          id: 1,
          icon: <FaListUl />,
          title: "Planning",
          text: "Brainstorming of initial structure according to requirements and creating wireframes.",
        },
        {
          id: 2,
          icon: <IoAnalyticsSharp />,
          title: "Analysis",
          text: "Doing market research and analysing things which can be a valuable part of the project. Including customer journey and user experience.",
        },
        {
          id: 3,
          icon: <MdOutlineDesignServices />,
          title: "Design",
          text: "Designing of the finalised structure.",
        },
        {
          id: 4,
          icon: <FaLaptopCode />,
          title: "Implementation",
          text: "Development of the project with functionalities and integrations.",
        },
        {
          id: 5,
          icon: <SiTestcafe />,
          title: "Testing",
          text: "Testing of developed products through QA Team.",
        },
        {
          id: 6,
          icon: <MdOutlineRocketLaunch />,
          title: "Review & Migration",
          text: "Taking valuable inputs from clients before final migration. Once approved, the migrating project is on the client's server.",
        },
      ],
    },
    faqList: [
      {
        id: 1,
        q: "What custom web development services do you offer?",
        a: "We specialize in custom web applications, e-commerce solutions, and CMS-based websites, tailored to meet your unique business requirements.",
      },
      {
        id: 2,
        q: "How do you ensure the quality of your web development projects?",
        a: "Our team follows a rigorous quality assurance process, including code reviews, testing protocols, and user feedback, to ensure optimal performance and security.",
      },
      {
        id: 3,
        q: "Can you integrate third-party tools and APIs into my website?",
        a: "Yes, we have extensive experience integrating various third-party tools and APIs to enhance functionality, from payment gateways to CRM systems.",
      },
      {
        id: 4,
        q: "What kind of support do you provide post-launch?",
        a: "We offer comprehensive post-launch support, including maintenance, updates, and troubleshooting to ensure your website continues to perform at its best.",
      },
      {
        id: 5,
        q: "How do you approach responsive web design?",
        a: "We implement a mobile-first design strategy, ensuring that your website provides an optimal experience across all devices and screen sizes.",
      },
    ],
    tools: [
      {
        id: 0,
        title: "React",
        icon: <SiReact />,
      },
      {
        id: 1,
        title: "Angular",
        icon: <IoLogoAngular />,
      },
      {
        id: 2,
        title: "Node",
        icon: <FaNodeJs />,
      },
      {
        id: 3,
        title: "Laravel",
        icon: <FaLaravel />,
      },
      {
        id: 4,
        title: "Php",
        icon: <FaPhp />,
      },
      {
        id: 5,
        title: "Mongodb",
        icon: <SiMongodb />,
      },
      {
        id: 6,
        title: "Sql",
        icon: <DiMysql />,
      },
    ],
  },
  {
    slug: paths.cms,
    process: {
      title: "From Concept to Creation",
      list: [
        {
          id: 1,
          icon: <FaListUl />,
          title: "Planning",
          text: "Brainstorming of initial structure according to requirements and creating wireframes.",
        },
        {
          id: 2,
          icon: <IoAnalyticsSharp />,
          title: "Analysis",
          text: "Doing market research and analysing things which can be a valuable part of the project. Including customer journey and user experience.",
        },
        {
          id: 3,
          icon: <MdOutlineDesignServices />,
          title: "Design",
          text: "Designing of the finalised structure.",
        },
        {
          id: 4,
          icon: <FaLaptopCode />,
          title: "Implementation",
          text: "Development of the project with functionalities and integrations.",
        },
        {
          id: 5,
          icon: <SiTestcafe />,
          title: "Testing",
          text: "Testing of developed products through QA Team.",
        },
        {
          id: 6,
          icon: <MdOutlineRocketLaunch />,
          title: "Review & Migration",
          text: "Taking valuable inputs from clients before final migration. Once approved, the migrating project is on the client's server.",
        },
      ],
    },
    faqList: [
      {
        id: 1,
        q: "What custom web development services do you offer?",
        a: "We specialize in custom web applications, e-commerce solutions, and CMS-based websites, tailored to meet your unique business requirements.",
      },
      {
        id: 2,
        q: "How do you ensure the quality of your web development projects?",
        a: "Our team follows a rigorous quality assurance process, including code reviews, testing protocols, and user feedback, to ensure optimal performance and security.",
      },
      {
        id: 3,
        q: "Can you integrate third-party tools and APIs into my website?",
        a: "Yes, we have extensive experience integrating various third-party tools and APIs to enhance functionality, from payment gateways to CRM systems.",
      },
      {
        id: 4,
        q: "What kind of support do you provide post-launch?",
        a: "We offer comprehensive post-launch support, including maintenance, updates, and troubleshooting to ensure your website continues to perform at its best.",
      },
      {
        id: 5,
        q: "How do you approach responsive web design?",
        a: "We implement a mobile-first design strategy, ensuring that your website provides an optimal experience across all devices and screen sizes.",
      },
    ],
    tools: [
      {
        id: 0,
        title: "React",
        icon: <SiReact />,
      },
      {
        id: 1,
        title: "Angular",
        icon: <IoLogoAngular />,
      },
      {
        id: 2,
        title: "Node",
        icon: <FaNodeJs />,
      },
      {
        id: 3,
        title: "Laravel",
        icon: <FaLaravel />,
      },
      {
        id: 4,
        title: "Php",
        icon: <FaPhp />,
      },
      {
        id: 5,
        title: "Mongodb",
        icon: <SiMongodb />,
      },
      {
        id: 6,
        title: "Sql",
        icon: <DiMysql />,
      },
    ],
  },
 
];
