import { useContext, useEffect } from "react";
import "./App.scss";
import { Route, Routes, useLocation } from "react-router-dom";
import { webContext } from "./webContext/WebContext";
import { ParallaxProvider } from "react-scroll-parallax";
import {
  Home,
  About,
  Blogs,
  CaseStudies,
  CaseStudyDetail,
  BlogDetails,
  Services,
  Clients,
  ErrorPage,
  ServiceDetail,
} from "./pages/index";
import DataAnalytics from "./pages/dataAnalytics/DataAnalytics";
import { paths } from "./utils/path";

function App() {
  const location = useLocation();

  const { startLoader, closeLoader } = useContext(webContext);

  useEffect(() => {
    startLoader();

    setTimeout(() => {
      closeLoader();
    }, 2000);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <ParallaxProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path={paths.about} element={<About />} />
        <Route path={paths.blogs} element={<Blogs />} />
        <Route path="/blogs/:slug" element={<BlogDetails />} />
        <Route path={paths.caseStudies} element={<CaseStudies />} />
        <Route path="/casestudies/:id" element={<CaseStudyDetail />} />
        <Route path={paths.services} element={<Services />} />
        <Route path="/industry/:slug" element={<ServiceDetail />} />
        <Route path="/services/:slug" element={<ServiceDetail />} />
        <Route path={paths.dataAnalytics} element={<DataAnalytics />} />
        <Route path={paths.clients} element={<Clients />} />
        <Route path="/*" element={<ErrorPage />} />
      </Routes>
    </ParallaxProvider>
  );
}

export default App;
