import { Fragment } from "react";
import "./ServiceOfferings.scss";
import OfferingPill from "./offeringPill/OfferingPill";
import ScrollAnimationSection from "../../../components/scrollAnimSec/ScrollAnimSec";

const ServiceOfferings = ({ why, title, classList }) => {
  return (
    <section className="services_offerings">
      <div className={`content_wrap ${classList}`}>
        <ScrollAnimationSection>
          <h2 className="text-center">{title}</h2>
        </ScrollAnimationSection>

        {why?.map((data) => {
          return (
            <Fragment key={data.id}>
              <OfferingPill data={data} />
            </Fragment>
          );
        })}
      </div>
    </section>
  );
};

export default ServiceOfferings;
