import { Fragment, createRef } from "react";
import "./MidTab.scss";
import ServicesSec from "../serviesSec/ServicesSec";
import StrategiesSec from "../strategiesSec/StrategiesSec";
import ReviewSlide from "../reviewSlide/ReviewSlide";
import ScrollSpy from "react-scrollspy-navigation";
import AboutCaseStudy from "../aboutCaseStudy/AboutCaseStudy";
import AboutProcess from "../aboutProcess/AboutProcess";
import Team from "../team/Team";
import MultiGridInfo from "../multiGridInfo/MultiGridInfo";
import MapLocationInfo from "../mapLocationInfo/MapLocationInfo";

import { FaListUl } from "react-icons/fa";
import { IoAnalyticsSharp } from "react-icons/io5";
import { MdOutlineDesignServices } from "react-icons/md";
import { FaLaptopCode } from "react-icons/fa";
import { SiTestcafe } from "react-icons/si";
import { MdOutlineRocketLaunch } from "react-icons/md";

const MidTab = ({ extraH }) => {
  const goTo = (secId) => {
    const section = document.getElementById(secId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop + extraH + 400,
        behavior: "smooth",
      });
    }
  };

  const processData = [
    {
      id: 0,
      img: <FaListUl />,
      title: "Planning",
      text: "Brainstorming of initial structure according to requirements and creating wireframes.",
    },
    {
      id: 1,
      img: <IoAnalyticsSharp />,
      title: "Analysis",
      text: "Doing market research and analysing things which can be a valuable part of the project including customer journey and user experience with detailed competitor analysis and heuristic evaluation.",
    },
    {
      id: 2,
      img: <MdOutlineDesignServices />,
      title: "Design",
      text: "Designing of the finalised structure.",
    },
    {
      id: 3,
      img: <FaLaptopCode />,
      title: "Implementation",
      text: "Development of the project with functionalities and integrations.",
    },
    {
      id: 4,
      img: <SiTestcafe />,
      title: "Testing",
      text: "Testing of developed product by the QA Team.",
    },
    {
      id: 5,
      img: <MdOutlineRocketLaunch />,
      title: "Review & Migration",
      text: "Taking valuable inputs from clients before final migration.Once approved, the project is migrated to the client's server.",
    },
  ];

  return (
    <Fragment>
      <div style={{ position: "relative", zIndex: "4" }}>
        <section className="mid_tab">
          <div className="content_wrap">
            <div className="md_tab_line">
              <ScrollSpy activeClass="active">
                <div className="tab_wraper_line">
                  <div className="btn_wrap">
                    <button type="button" onClick={() => goTo("secA")}>
                      Services
                    </button>
                    <button type="button" onClick={() => goTo("secB")}>
                      Projects
                    </button>
                    <button type="button" onClick={() => goTo("secC")}>
                      Process
                    </button>
                    <button type="button" onClick={() => goTo("secD")}>
                      Review
                    </button>
                    <button type="button" onClick={() => goTo("secE")}>
                      Quote
                    </button>
                    <button type="button" onClick={() => goTo("secF")}>
                      Team
                    </button>
                    <button type="button" onClick={() => goTo("secG")}>
                      Presence
                    </button>
                  </div>
                  <a href="#secA" ref={createRef()}>
                    Services
                  </a>
                  <a href="#secB" ref={createRef()}>
                    Projects
                  </a>
                  <a href="#secC" ref={createRef()}>
                    Process
                  </a>
                  <a href="#secD" ref={createRef()}>
                    Review
                  </a>
                  <a href="#secE" ref={createRef()}>
                    Quote
                  </a>
                  <a href="#secF" ref={createRef()}>
                    Team
                  </a>
                  <a href="#secG" ref={createRef()}>
                    Presence
                  </a>
                </div>
              </ScrollSpy>
            </div>
          </div>
        </section>

        <ServicesSec />

        <AboutCaseStudy />
        
        <div id="secC">
          <StrategiesSec
            t1="Efficient Workflow:"
            t2="From Vision to Reality"
            para=" Streamlined for efficiency and clarity, we collaborate closely
                to deliver exceptional results on time."
            dataList={processData}
          />
        </div>

        <ReviewSlide />

        <AboutProcess />

        <Team />

        <MultiGridInfo />

        <MapLocationInfo />
      </div>
    </Fragment>
  );
};

export default MidTab;
